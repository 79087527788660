import Vue from 'vue';

const initialState = () => {
    return {
        isLoading: false,
        productsToAdd: [],
        productRow: [],

        quickBuyQuote: [],
        getQuickBuyBulkIds: [],
        bulkSettings: {
            sap_margin: null,
            custom_price: null,
            sap_discount: null,
            delivery_date: null,
        }
    }
};

const mutations = {
    SET_PRODUCTS_TO_ADD(state, payload: object): void {
        const productIndex = state.productsToAdd.findIndex(p => p.entity_id === payload['entity_id']);
        const productLength = state.productsToAdd.length;

        if (productIndex >= 0) {
            Vue.delete(state.productsToAdd, productIndex);
            return;
        }

        Vue.set(state.productsToAdd, productLength, payload);
    },
    SET_PRODUCT_ROW(state): void {
        Vue.set(state, 'productRow', state.productsToAdd);
        Vue.set(state, 'productsToAdd', []);
    },
    RESET_PRODUCT_ROW(state): void {
        Vue.set(state, 'productRow', []);
        Vue.set(state, 'productsToAdd', []);
    },
    SET_QUICK_BUY_BULK_IDS(state, payload: number): void {
        const bulkIds = state.getQuickBuyBulkIds.includes(payload);
        const newBulkIds = bulkIds ? state.getQuickBuyBulkIds.filter(b => b !== payload) : state.getQuickBuyBulkIds.concat(payload);

        Vue.set(state, 'getQuickBuyBulkIds', newBulkIds);
    },
    TOGGLE_QUICK_BUY_BULK_IDS(state, payload: Array<number>): void {
        Vue.set(state, 'getQuickBuyBulkIds', payload);
    },
    SET_TOTAL_PRICE(state, payload: number): void {
        Vue.set(state, 'totalPrice', payload);
    }
};

const actions = {
    setProductsToAdd({commit}, payload: Array<object>): void {
        commit('SET_PRODUCTS_TO_ADD', payload);
    },
    setProductRow({commit}): void {
        commit('SET_PRODUCT_ROW');
    },
    resetProductRow({commit}): void {
        commit('RESET_PRODUCT_ROW');
    },
    setQuickBuyBulkIds({commit}, payload: number): void {
        commit('SET_QUICK_BUY_BULK_IDS', payload);
    },
    toggleQuickBuyBulkIds({commit}, payload: Array<number>): void {
        commit('TOGGLE_QUICK_BUY_BULK_IDS', payload);
    }
};

const getters = {
    getProductsToAdd: state => state.productsToAdd,
    getProductRow: state => state.productRow,
    getQuickBuyQuote: (state, getters, rootState, rootGetters) => rootGetters['CheckoutTotals/cartItems'].map((item: object) => {
        return {
            item_id: item['item_id'],
            model_id: item['extension_attributes']['version_id'] || item['extension_attributes']['model_id'],
            sku: item['extension_attributes']['sku'],
            supplier_code: item['extension_attributes']['supplier_code'],
            size: item['extension_attributes']['size'] || null,
            color: item['extension_attributes']['color'] || null,
            name: item['name'],
            price: item['extension_attributes']['from_price'],
            nett_retail_price: isNaN(item['extension_attributes']['netto_retail_price']) ? parseFloat(item['extension_attributes']['netto_retail_price']) : item['extension_attributes']['netto_retail_price'],
            custom_price: item['base_price'],
            sap_margin: item['extension_attributes']['margin'] || '0.00',
            sap_discount: item['extension_attributes']['discount'] || '0.00',
            delivery_date: item['extension_attributes']['delivery_date'] || null,
            quantity: item['qty'],
        };
    }).reverse(),
    getQuickBuyBulkIds: state => state.getQuickBuyBulkIds,
    getTotalPrice: (state, getters) => getters.getQuickBuyQuote.reduce((acc, item) => acc + (item.custom_price * item.quantity), 0),
    getBulkSettings: state => state.bulkSettings,
};

const state = initialState();

const QuickBuy = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

export default QuickBuy;
