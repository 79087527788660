import Vue from 'vue';

const initialState = () => {
    return {
        isLoading: false,
        isExporting: false,
        formSubmitted: false,

        searchParams: {
            brand: '',
            barcode: '',
            sku: '',
            ean: '',
            suppliercode: '',
            name: '',
            size: '',
        },
        serviceBrands: [],
        serviceProducts: [],
        hasResults: true,

        productsToAdd: [],
        productRow: [],

        additionalFields: [],

        serviceHasBeenSubmitted: false,
        serviceError: null,
    }
};

const mutations = {
    SET_IS_LOADING(state, payload) {
        state.isLoading = payload;
    },
    SET_HAS_RESULTS(state, payload) {
        state.hasResults = payload;
    },
    SET_FORM_SUBMITTED(state, payload) {
        state.formSubmitted = payload;
    },

    SET_SERVICE_BRANDS(state, payload) {
        state.serviceBrands = payload;
    },
    SET_SERVICE_PRODUCTS(state, payload) {
        state.serviceProducts = payload;
    },
    SET_SEARCH_PARAMS(state, payload) {
        state.searchParams = payload;
    },
    SET_PRODUCTS_TO_ADD(state, payload: object): void {
        const productIndex = state.productsToAdd.findIndex(p => p.entity_id === payload['entity_id']);
        const productLength = state.productsToAdd.length;

        if (payload['qty'] <= 0) {
            Vue.delete(state.productsToAdd, productIndex);
            return;
        }

        if (productIndex >= 0) {
            Vue.set(state.productsToAdd, productIndex, payload);
            return;
        }

        Vue.set(state.productsToAdd, productLength, payload);
    },
    SET_PRODUCT_ROW(state): void {
        const prepareProductRow = state.productsToAdd.filter(p => ! state.productRow.includes(p));
        const newProductRow = state.productRow.concat(prepareProductRow);

        Vue.set(state, 'productRow', newProductRow);
        Vue.set(state, 'productsToAdd', []);
        Vue.set(state, 'serviceProducts', []);
    },
    REMOVE_FROM_PRODUCT_ROW(state, payload: number): void {
        const index = state.productRow.findIndex(p => p['entity_id'] === payload);

        if (index >= 0) {
            Vue.delete(state.productRow, index);
        }
    },
    RESET_PRODUCT_ROW(state): void {
        Vue.set(state, 'productRow', []);
        Vue.set(state, 'productsToAdd', []);
        Vue.set(state, 'serviceProducts', []);
    },
    SET_ADDITIONAL_FIELDS(state, payload): void {
        state.additionalFields = payload;
    },
    SET_SERVICE_HAS_BEEN_SUBMITTED(state, payload): void {
        state.serviceHasBeenSubmitted = payload;
    },
    SET_SERVICE_ERROR(state, payload): void {
        state.serviceError = payload;
    },
    SET_IS_EXPORTING(state, payload): void {
        state.isExporting = payload;
    },
};

const actions = {
    async fetchBrands({commit}): Promise<void> {
        commit('SET_IS_LOADING', true);

        try {
            const {data} = await Vue.prototype.$solarClient.get('/api/service/fetch/brands');

            commit('SET_SERVICE_BRANDS', data ? Object.values(data) : []);

        } catch (e) {
            console.error(e);
        } finally {
            commit('SET_IS_LOADING', false);
        }
    },
    async searchProducts({state, commit}): Promise<void> {
        commit('SET_IS_LOADING', true);

        if (state.serviceHasBeenSubmitted) {
            commit('SET_SERVICE_ERROR', null);
            commit('SET_SERVICE_HAS_BEEN_SUBMITTED', false);
            commit('RESET_PRODUCT_ROW', []);
        }

        try {
            let payload = {...state.searchParams};
            if (payload.hasOwnProperty('brand') && typeof payload.brand === 'object') {
                payload.brand = payload.brand?.id || '';
            }

            const serviceProducts = await Vue.prototype.$solarClient.post('/api/service/fetch/products', payload, {timeout: 50000});

            commit('SET_HAS_RESULTS', serviceProducts.data.length);
            commit('SET_SERVICE_PRODUCTS', serviceProducts.data);
        } catch (e) {
            console.error(e);
        } finally {
            commit('SET_IS_LOADING', false);
        }
    },

    closeSearchResults({commit}): void {
        commit('SET_HAS_RESULTS', true);
        commit('SET_SERVICE_PRODUCTS', []);
    },

    async fetchAdditionalFields({state, commit}): Promise<void> {
        let families = state.productRow.reduce((families, item) => item['product']['service_family'] ?
            families.includes(item['product']['service_family']) ? families : families.concat(item['product']['service_family']) :
            families, []);

        families = await Promise.all(
            families.map(async (family: string) => {
                const subTypes = await Vue.prototype.$solarClient.get(`/api/service/fetch/subtypes/${family}`);
                const locations = await Vue.prototype.$solarClient.get(`/api/service/fetch/locations/${family}`);

                return {
                    family: family,
                    subTypes: subTypes.data,
                    locations: locations.data,
                };
            })
        );

        commit('SET_ADDITIONAL_FIELDS', families);
    },

    setProductsToAdd({commit}, payload: Array<object>): void {
        commit('SET_PRODUCTS_TO_ADD', payload);
        commit('SET_FORM_SUBMITTED', false);
    },
    setProductRow({commit}): void {
        commit('SET_PRODUCT_ROW');
    },
    removeFromProductRow({commit}, payload: number): void {
        commit('REMOVE_FROM_PRODUCT_ROW', payload);
    },
    resetProductRow({commit}): void {
        commit('RESET_PRODUCT_ROW');
    },

    setFormSubmitted({commit}, payload: boolean): void {
        commit('SET_FORM_SUBMITTED', payload);
    },

    async submitServiceForm({state, commit}): Promise<void> {
        commit('SET_IS_LOADING', true);

        try {
            const result = await Promise.all(
                state.productRow.map(async (product: object) => {
                    const response = await Vue.prototype.$solarClient.post('/api/service/post/service-call', product['payload']);

                    return response.data;
                })
            );

            if (result) {
                result.forEach(({data}, index: number) => {
                    state.productRow[index]['payload']['service_nr'] = data['service_nr'];
                });

                commit('SET_SERVICE_HAS_BEEN_SUBMITTED', true);
            }

        } catch (e) {
            commit('SET_SERVICE_ERROR', 'portal-invoice-parameters-error');
            console.error(e);
        } finally {
            commit('SET_IS_LOADING', false);
        }
    },

    async exportService({state, commit}): Promise<void> {
        commit('SET_IS_LOADING', true);
        commit('SET_IS_EXPORTING', true);

        try {
            const response = await Vue.prototype.$solarClient.post(
                '/api/service/post/export', {
                    payload: state.productRow.map((product: object) => product['payload']),
                },
                {responseType: 'blob'}
            );

            if (response.data) {
                const link = document.createElement('a');
                link.setAttribute('download', response.headers['filename']);
                link.href = window.URL.createObjectURL(new Blob([response.data]));
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        } catch (e) {
            commit('SET_REPORT_ERROR', e.error);
            console.error(e);
        } finally {
            commit('SET_IS_LOADING', false);
            commit('SET_IS_EXPORTING', false);
        }
    },
};

const getters = {
    isLoading: state => state.isLoading,
    isExporting: state => state.isExporting,
    formSubmitted: state => state.formSubmitted,

    hasResults: state => state.hasResults,
    serviceBrands: state => state.serviceBrands,
    serviceProducts: state => state.serviceProducts,
    searchParams: state => state.searchParams,

    getProductsToAdd: state => state.productsToAdd,
    getProductRow: state => state.productRow,

    getAdditionalFields: state => state.additionalFields,
    getServiceHasBeenSubmitted: state => state.serviceHasBeenSubmitted,
    getServiceError: state => state.serviceError,
};

const state = initialState();

const ServiceForm = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

export default ServiceForm;
