import MobileDetect from '../../default/js/shared/helpers/mobile-detect';

const mobileDetect = new MobileDetect();

('.header__search--nav nav>ul').dropDown(el => {
    el.classList.add('--active');
}, el => {
    el.classList.remove('--active');
}, 200);

window.addEventListener('load', () => {
    if (mobileDetect.mobile()) {
        const menuItems = document.querySelectorAll('.--menu .menu > ul > li');

        menuItems.forEach((item) => {
            item.firstElementChild.addEventListener('click', (e) => {
                if(item.classList.contains('--has-children')) {
                    e.preventDefault();
                    item.classList.toggle('--active');
                }
            });
        });
    }
});
