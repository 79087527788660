import Vue from 'vue';

const initialState = () => {
    return {
        isLoading: false,
        showOffCanvas: false,
        activeQuoteId: null,
        activeQuoteTitle: null,
        activeQuoteSapId: null,
        defaultSortOptions: {},

        logEntries: {},
        pageSize: 50,
    }
};

const mutations = {
    SET_IS_LOADING(state: object, payload: boolean): void {
        Vue.set(state, 'isLoading', payload);
    },
    SET_SHOW_OFF_CANVAS(state: object, payload: boolean): void {
        Vue.set(state, 'showOffCanvas', payload);
    },
    SET_ACTIVE_QUOTE_ID(state: object, payload: number): void {
        Vue.set(state, 'activeQuoteId', payload);
    },
    SET_ACTIVE_QUOTE_TITLE(state: object, payload: string): void {
        Vue.set(state, 'activeQuoteTitle', payload);
    },
    SET_ACTIVE_QUOTE_SAP_ID(state: object, payload: string): void {
        Vue.set(state, 'activeQuoteSapId', payload);
    },
    SET_LOG_ENTRIES(state: object, payload: object): void {
        Vue.set(state, 'logEntries', payload);
    },
    SET_PAGE_SIZE(state: object, payload: number): void {
        Vue.set(state, 'pageSize', payload);
    },
};

const actions = {
    async fetchLogs({state, commit, dispatch}, payload: object): Promise<void> {
        if (payload['quoteId']) {
            await dispatch('resetStates', payload);
        }

        commit('SET_IS_LOADING', true);

        try {
            const {data} = await this.$solarClient.get(`/api/preorder/fetch/quote/logs/${state.activeQuoteId}?pageSize=${state.pageSize}`);

            if (data) {
                commit('SET_LOG_ENTRIES', data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            commit('SET_IS_LOADING', false);
            commit('SET_SHOW_OFF_CANVAS', true);
        }
    },
    resetStates({commit}, payload: object): void {
        commit('SET_LOG_ENTRIES', []);
        commit('SET_ACTIVE_QUOTE_ID', null);
        commit('SET_ACTIVE_QUOTE_TITLE', null);
        commit('SET_ACTIVE_QUOTE_SAP_ID', null);
        commit('SET_SHOW_OFF_CANVAS', false);

        commit('SET_ACTIVE_QUOTE_ID', payload['quoteId']);
        commit('SET_ACTIVE_QUOTE_TITLE', payload['quoteTitle']);
        commit('SET_ACTIVE_QUOTE_SAP_ID', payload['quoteSapId']);
    },
    setPageSize({commit, dispatch}, payload: number): void {
        commit('SET_PAGE_SIZE', payload);
        dispatch('fetchLogs', {});
    }
};

const getters = {
    isLoading: (state: object) => state['isLoading'],
    showOffCanvas: (state: object) => state['showOffCanvas'],
    activeQuoteId: (state: object) => state['activeQuoteId'],
    activeQuoteTitle: (state: object) => state['activeQuoteTitle'],
    activeQuoteSapId: (state: object) => state['activeQuoteSapId'],
    logEntries: (state: object) => state['logEntries'],
    pageSize: (state: object) => state['pageSize'],
};

const state = initialState();

const PreorderLogs = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

export default PreorderLogs;
