import Vue from 'vue';
import {appSessionStorage} from '../../shared/services';

const initialState = () => {
    return {
        isLoading: false,
        fetchCustomers: true,

        selectedCustomer: null,
        customerList: appSessionStorage.getItem('customerList', []),
        customerListIsSalesForce: appSessionStorage.getItem('customerListIsSalesForce', false),
    }
};

const mutations = {
    SET_IS_LOADING(state, payload) {
        Vue.set(state, 'isLoading', payload);
    },
    SET_CUSTOMER_LIST(state, payload) {
        Vue.set(state, 'customerList', payload);
        appSessionStorage.setItem('customerList', payload);
    },
    SET_CUSTOMER_LIST_IS_SALES_FORCE(state, payload) {
        Vue.set(state, 'customerListIsSalesForce', payload);
        appSessionStorage.setItem('customerListIsSalesForce', payload);
    },
    SET_SELECTED_CUSTOMER(state, payload) {
        Vue.set(state, 'selectedCustomer', payload);
    },
    SET_FETCH_CUSTOMERS(state, payload) {
        Vue.set(state, 'fetchCustomers', payload);
    }
};

const actions = {
    async fetchCustomerList({state, commit}, salesForceIsEnabled = false) {
        if ( ! state.fetchCustomers) {
            return;
        }

        if (salesForceIsEnabled === state.customerListIsSalesForce && state.customerList.length > 0) {
            return;
        }

        commit('SET_FETCH_CUSTOMERS', false);
        commit('SET_IS_LOADING', true);
        commit('SET_CUSTOMER_LIST_IS_SALES_FORCE', salesForceIsEnabled);

        const params = new URLSearchParams({
            salesForce: salesForceIsEnabled ? 'true' : 'false',
        });

        try {
            const {data} = await Vue.prototype.$solarClient.get(`/api/customer/select/list?${params.toString()}`, {
                timeout: 30000,
            });

            if (data) {
                commit('SET_CUSTOMER_LIST', data);
            }
        } catch (e) {
            console.error(e);
        } finally {
            commit('SET_IS_LOADING', false);
        }
    },
    async setSelectedCustomer({commit, dispatch}, payload: object): Promise<void> {
        if ( ! payload['fresh']) {
            commit('SET_SELECTED_CUSTOMER', payload['customer'] || null);

            if (payload['fetchQuote'] && payload['customer']) {
                await dispatch('CheckoutTotals/fetchCartTotals', null, {root: true});
            }

            return;
        }

        const fullscreenLoader = document.getElementById('fc-loader');
        fullscreenLoader.style.display = 'flex';

        commit('SET_IS_LOADING', true);

        const params = new URLSearchParams({
            customer: payload['customer']['id'],
            salesForce: payload['salesForceIsEnabled'] ? 'true' : 'false',
            reporting: payload['salesForceIsEnabled'] ? 'true' : 'false',
        });

        try {
            const {data} = await Vue.prototype.$solarClient.get(`api/customer/change-customer?${params.toString()}`);

            if (data) {
                commit('SET_SELECTED_CUSTOMER', payload['customer']);
            }
        } catch (e) {
            console.error(e);
        } finally {
            commit('SET_IS_LOADING', false);

            if (payload['fetchQuote']) {
                await dispatch('CheckoutTotals/fetchCartTotals', null, {root: true});
            }

            //TODO: Evaluate if this fixes the issues with the customer session
            // if (payload['needsReload']) {
                window.location.reload();
            // }
        }
    }
};

const getters = {
    getIsLoading: state => state.isLoading,
    getCustomerList: state => state.customerList,
    getSelectedCustomer: state => state.selectedCustomer,
};

const state = initialState();

const GlobalCustomer = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

export default GlobalCustomer;
