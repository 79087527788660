const initialState = () => {
    return {
        isLoading: false,
        cartTotals: {},
        cartItems: [],
        totalsAvailable: false,
    };
};

const getters = {
    cartItems: (state) => state.cartItems,
    cartTotals: (state) => state.cartTotals,
    totalsAvailable: (state) => state.totalsAvailable,
    isLoading: (state) => state.isLoading,
};

const mutations = {
    SET_CART_TOTALS(state, payload) {
        state.cartTotals = payload;
    },
    SET_CART_ITEMS(state, payload) {
        state.cartItems = payload;
    },
    SET_TOTALS_AVAILABLE(state, payload) {
        state.totalsAvailable = payload;
    },
    SET_LOADING(state, payload) {
        state.isLoading = payload;
    },
};

const actions = {
    async fetchCartTotals({commit}) {
        commit('SET_LOADING', true);

        try {
            commit('SET_TOTALS_AVAILABLE', false);
            const {data} = await this.$solarClient.get(`/api/checkout/totals/collect`);

            window.dispatchEvent(new CustomEvent('CheckoutSetCartCountEvent', {detail: data['items_qty'] || 0}));

            commit('SET_CART_TOTALS', data);
            commit('SET_CART_ITEMS', data['items']);
        } catch (err) {
            //
        } finally {
            commit('SET_TOTALS_AVAILABLE', true);
            commit('SET_LOADING', false);
        }
    },
};

const state = initialState();

const CheckoutTotals = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

export default CheckoutTotals;
