export class IntersectionObserverHelper {
    static config: object = {
        root: null,
        rootMargin: '0px',
        threshold: 0
    };

    /**
     * Initialize the IntersectionObserver
     *
     * @param config
     * @param observeTargets
     */
    public initIntersectionObserver(config?: object, observeTargets?: NodeListOf<Element>): void {
        if (config) {
            IntersectionObserverHelper.config = {
                ...IntersectionObserverHelper.config,
                ...config
            };
        }

        const observer: IntersectionObserver = new IntersectionObserver(this.itemObserverHandler, IntersectionObserverHelper.config);
        const observeTargetList: NodeListOf<Element> = observeTargets || document.getElementById('list-observable').querySelectorAll('*[data-observe]');

        for (let i: number = 0; i < observeTargetList.length; i++) {
            observer.observe(observeTargetList[i]);
        }
    }

    /**
     * IntersectionObserver callback
     *
     * @param entries
     * @param observer
     * @protected
     */
    protected itemObserverHandler(entries: IntersectionObserverEntry[], observer: IntersectionObserver): void {
        entries.map((entry: IntersectionObserverEntry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('--in-view');
            } else {
                entry.target.classList.remove('--in-view');
            }
        });
    }

    /**
     * Initialize the IntersectionObserver for the page builder
     */
    public initIntersectionObserverForPageBuilder(): void {
        this.initIntersectionObserver({
            threshold: 0.3
        });
    }
}

const intersectionObserverHelper: IntersectionObserverHelper = new IntersectionObserverHelper();

export {intersectionObserverHelper};
