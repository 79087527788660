const initialState = () => {
  return {
    messages: [],
  }
};

const state = initialState();

const mutations = {
  SET_MESSAGE(state, payload) {
    state['messages'].push(payload);
  },
  REMOVE_MESSAGE(state, payload) {
    state['messages'].splice(state['messages'].indexOf(payload), 1);
  },
};

const actions = {
  addGlobalMessage({state, commit}, payload) {
    commit('SET_MESSAGE', payload);
    setTimeout(() => {
      commit('REMOVE_MESSAGE', payload);
    }, 5000);
  },
};

const GlobalMessages = {
  namespaced: true,
  state,
  mutations,
  actions,
};

export default GlobalMessages;
