import Vue from 'vue';

const initialState = () => {
    return {
        reportError: null,
        isLoading: false,
        isExporting: false,

        selectedReport: null,
        showParameters: false,

        selectedReportParameters: null,
        selectedReportResult: null,

        selectedReportHasDetails: false,
        selectedReportDetails: {},
    }
};

const mutations = {
    SET_REPORT_ERROR(state, payload): void {
        state.reportError = payload;
    },
    SET_IS_LOADING(state, payload): void {
        state.isLoading = payload;
    },
    SET_IS_EXPORTING(state, payload): void {
        state.isExporting = payload;
    },
    SET_SELECTED_REPORT(state, payload): void {
        state.selectedReport = payload;
    },
    SET_SELECTED_REPORT_PARAMETERS(state, payload): void {
        state.selectedReportParameters = payload;
    },
    SET_SHOW_PARAMETERS(state, payload): void {
        state.showParameters = payload;
    },
    SET_SELECTED_REPORT_RESULT(state, payload): void {
        state.selectedReportResult = payload;
    },
    SET_SELECTED_REPORT_HAS_DETAILS(state, payload): void {
        state.selectedReportHasDetails = payload;
    },
    SET_SELECTED_REPORT_DETAILS(state, payload): void {
        Vue.set(state.selectedReportDetails, payload.index, payload.data);
    },
    CLEAR_SELECTED_REPORT_DETAILS(state): void {
        state.selectedReportDetails = {};
    }
};

const actions = {
    setSelectedReport({commit, dispatch}, payload): void {
        commit('SET_SELECTED_REPORT', payload);
        commit('SET_SELECTED_REPORT_RESULT', null);
        commit('SET_SHOW_PARAMETERS', true);
        dispatch('getReportParameters', payload.id);
    },
    showParameters({commit}, payload): void {
        commit('SET_SHOW_PARAMETERS', payload);
    },
    async getReportParameters({commit}, payload): Promise<void> {
        commit('SET_IS_LOADING', true);

        try {
            const {data} = await Vue.prototype.$solarClient.get(`/api/report/fetch/report/${payload}/query/main/parameters`);

            if (data) {
                commit('SET_SELECTED_REPORT_PARAMETERS', data);
                commit('SET_SHOW_PARAMETERS', true);
            }
        } catch (e) {
            commit('SET_REPORT_ERROR', e.error);
            console.error(e);
        } finally {
            commit('SET_IS_LOADING', false);
        }
    },
    async getReport({commit, getters, dispatch}): Promise<void> {
        commit('SET_IS_LOADING', true);

        try {
            const {data} = await Vue.prototype.$solarClient.post(`/api/report/fetch/report/${getters.getSelectedReportId}/query/main/result`, {
                payload: getters.getSelectedReportParameters,
            });

            commit('CLEAR_SELECTED_REPORT_DETAILS');

            if (data) {
                await dispatch('fetchHasDetails');

                commit('SET_SELECTED_REPORT_RESULT', data);
                commit('SET_SHOW_PARAMETERS', false);
            }
        } catch (e) {
            commit('SET_REPORT_ERROR', e.error);
            console.error(e);
        } finally {
            commit('SET_IS_LOADING', false);
        }
    },
    async fetchHasDetails({commit, getters}): Promise<void> {
        try {
            const {data} = await Vue.prototype.$solarClient.get(`/api/report/fetch/report/${getters.getSelectedReportId}/query/detail/exists`);

            commit('SET_SELECTED_REPORT_HAS_DETAILS', data && data.exists);
        } catch (e) {
            console.error(e);
        }
    },
    async exportReport({commit, getters}): Promise<void> {
        commit('SET_IS_LOADING', true);
        commit('SET_IS_EXPORTING', true);

        try {
            const response = await Vue.prototype.$solarClient.post(
                `/api/report/fetch/report/${getters.getSelectedReportId}/query/main/export`, {
                    payload: getters.getSelectedReportParameters,
                },
                {responseType: 'blob'}
            );

            if (response.data) {
                const link = document.createElement('a');
                link.setAttribute('download', response.headers['filename']);
                link.href = window.URL.createObjectURL(new Blob([response.data]));
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        } catch (e) {
            commit('SET_REPORT_ERROR', e.error);
            console.error(e);
        } finally {
            commit('SET_IS_LOADING', false);
            commit('SET_IS_EXPORTING', false);
        }
    },
    async setSelectedReportResultDetails({state, commit, getters}, payload): Promise<boolean> {
        if (state.selectedReportDetails[payload.index]) {
            return false;
        }

        let success = false;

        commit('SET_IS_LOADING', true);

        try {
            const {data} = await Vue.prototype.$solarClient.post(`/api/report/fetch/report/${getters.getSelectedReportId}/query/detail/result`, {
                payload: {
                    ...getters.getSelectedReportParameters,
                    __object__: payload.row,
                },
            });

            if (data) {
                commit('SET_SELECTED_REPORT_DETAILS', {
                    index: payload.index,
                    data: data,
                });
                success = true;
            }
        } catch (e) {
            commit('SET_REPORT_ERROR', e.error);
            console.error(e);
        } finally {
            commit('SET_IS_LOADING', false);
        }

        return success;
    }
};

const getters = {
    getReportError: state => state.reportError,
    getIsLoading: state => state.isLoading,
    getIsExporting: state => state.isExporting,
    getSelectedReport: state => state.selectedReport,
    getSelectedReportParameters: state => state.selectedReportParameters,
    getShowParameters: state => state.showParameters,
    getSelectedReportId: state => state.selectedReport ? state.selectedReport.id : null,
    getSelectedReportResult: state => state.selectedReportResult,
    getHasSelectedReportResult: state => state.selectedReportResult && state.selectedReportResult.headers.length > 0,
    getSelectedReportHasDetails: state => state.selectedReportHasDetails,
    getSelectedReportDetails: state => state.selectedReportDetails,
};

const state = initialState();

const Report = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

export default Report;
