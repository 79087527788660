import Vue from "vue";

const initialState = () => {
    return {
        isAddToQuoteLoading: false,
        addToQuoteMessage: null,
        afterAddToQuoteMessage: [],
    };
};

const mutations = {
    SET_ADD_TO_QUOTE_MESSAGE(state, message) {
        state.addToQuoteMessage = message;
    },
    SET_IS_ADD_TO_QUOTE_LOADING(state, payload: boolean): void {
        Vue.set(state, 'isAddToQuoteLoading', payload);
    },
    SET_AFTER_ADD_TO_QUOTE_MESSAGE(state, message) {
        state.afterAddToQuoteMessage = message;
    },
};

const actions = {
    async addProductToQuote({state, commit, dispatch}, productPayload) {
        try {
            const {data} = await this.$solarClient.post(
                '/api/checkout/quote/addProductToQuote',
                {
                    cartItem: productPayload,
                },
                {timeout: 25000}
            );

            window.dispatchEvent(new CustomEvent('CheckoutAddedProductToCartEvent'));
        } catch (e) {
            console.log(e);
            commit('SET_ADD_TO_QUOTE_MESSAGE', e.response.data.message);
        }
    },
    async addBulkProductsToQuote({commit, dispatch}, payload): Promise<boolean> {
        let success = false;

        commit('SET_IS_ADD_TO_QUOTE_LOADING', true);

        try {
            const {data} = await Vue.prototype.$solarClient.post('/api/cart/bulk-product', payload);

            if (data) {
                await dispatch('CheckoutTotals/fetchCartTotals', null, {root: true});
                window.dispatchEvent(new CustomEvent('CheckoutAddedProductToCartEvent'));

                if (data.hasOwnProperty('extension_attributes') && data.extension_attributes.hasOwnProperty('additional_data')) {
                    commit('SET_AFTER_ADD_TO_QUOTE_MESSAGE', JSON.parse(data.extension_attributes['additional_data'] || '[]'));
                }

                success = true;
            }
        } catch (e) {
            console.error(e);
        } finally {
            commit('SET_IS_ADD_TO_QUOTE_LOADING', false);
        }

        return success;
    },
  async importBulkProductsToQuote({commit, dispatch}, payload): Promise<boolean> {
    let success = false;

    commit('SET_IS_ADD_TO_QUOTE_LOADING', true);

    try {
      const {data} = await Vue.prototype.$solarClient.post('/api/cart/bulk-import-product', payload);

      if (data) {
        await dispatch('CheckoutTotals/fetchCartTotals', null, {root: true});
        window.dispatchEvent(new CustomEvent('CheckoutAddedProductToCartEvent'));

        if (data.hasOwnProperty('extension_attributes') && data.extension_attributes.hasOwnProperty('additional_data')) {
          commit('SET_AFTER_ADD_TO_QUOTE_MESSAGE', JSON.parse(data.extension_attributes['additional_data'] || '[]'));
        }

        success = true;
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_IS_ADD_TO_QUOTE_LOADING', false);
    }

    return success;
  },
};

const getters = {
    getIsAddToQuoteLoading: state => state.isAddToQuoteLoading,
    getAfterAddToQuoteMessage: state => state.afterAddToQuoteMessage,
};

const state = initialState();

const CheckoutQuote = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

export default CheckoutQuote;
