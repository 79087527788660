import Vue from 'vue';

const initialState = () => {
    return {
        isLoading: false,
        showResult: false,
        invoiceType: 'Invoice',
        toDate: new Date(),
        fromDate: null,
        results: [],
        messages: null,
    }
};

const mutations = {
    SET_IS_LOADING(state, payload) {
        Vue.set(state, 'isLoading', payload);
    },
    SET_SHOW_RESULT(state, payload) {
        Vue.set(state, 'showResult', payload);
    },
    SET_INVOICE_TYPE_SELECTION(state, payload) {
        Vue.set(state, 'invoiceType', payload);
    },
    SET_FROM_DATE(state, payload) {
        Vue.set(state, 'fromDate', payload);
    },
    SET_TO_DATE(state, payload) {
        Vue.set(state, 'toDate', payload);
    },
    SET_RESULT_LIST(state, payload) {
        Vue.set(state, 'results', payload);
    },
    SET_MESSAGES(state, payload) {
        Vue.set(state, 'messages', payload);
    }
};

const actions = {
    setInvoiceType({commit}, payload) {
        commit('SET_INVOICE_TYPE_SELECTION', payload);
    },
    setFromDate({commit}, payload) {
        commit('SET_FROM_DATE', payload);
    },
    setToDate({commit}, payload) {
        commit('SET_TO_DATE', payload);
    },
    resetState({commit}) {
        commit('SET_SHOW_RESULT', false);
        commit('SET_RESULT_LIST', []);
        commit('SET_MESSAGES', null);
    },
    async fetchResults({commit}) {
        commit('SET_IS_LOADING', true);

        let addDates = '';
        if (state.toDate && state.fromDate) {
            addDates = `from/${state.fromDate}/to/${state.toDate}`
        }

        try {
            const {data} = await Vue.prototype.$solarClient.get(`/api/invoice-copy/fetch/invoices/${state.invoiceType}/${addDates}`);

            if (data) {
                commit('SET_RESULT_LIST', data);
            }
        } catch (error) {
            commit('SET_MESSAGES', {
                type: '--error',
                message: 'portal-invoice-parameters-error'
            });
            console.error(error);
        } finally {
            commit('SET_MESSAGES', null);
            commit('SET_IS_LOADING', false);
            commit('SET_SHOW_RESULT', true);
        }
    },
    async sendCopy({commit}, payload) {
        commit('SET_IS_LOADING', true);

        try {
            const {data} = await Vue.prototype.$solarClient.get(`/api/invoice-copy/send/${state.invoiceType}/document/${payload}`);

            if (data) {
                commit('SET_MESSAGES', {
                    type: '--success',
                    message: 'portal-invoice-success'
                });
            }
        } catch (error) {
            commit('SET_MESSAGES', {
                type: '--error',
                message: 'portal-invoice-error'
            });
            console.error(error);
        } finally {
            commit('SET_IS_LOADING', false);
        }
    }
};

const getters = {
    getIsLoading: state => state.isLoading,
    getShowResult: state => state.showResult,
    getInvoiceType: state => state.invoiceType,
    getFromDate: state => state.fromDate,
    getToDate: state => state.toDate,
    getResultList: state => state.results,
    getMessages: state => state.messages,
}

const state = initialState();

const InvoiceCopy = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

export default InvoiceCopy;
