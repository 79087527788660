import Environment from '@flashpointbv/solar-ui-environment';
import {appSessionStorage} from '../services';
import {AxiosResponse} from "axios";

const initialState = () => {
    return {
        loadingPlaceOrder: false,
        checkoutErrorMessage: null,
        couponCode: appSessionStorage.getItem('checkout-payment-coupon-code', ''),
        couponStatus: null,
        couponMessage: '',
        couponRequestIsLoading: false,

        paymentMethods: [{}],
        paymentMethodOptions: {},
        paymentMethodConfig: [{}],
        loadingPaymentData: false,
        selectedPaymentMethod: appSessionStorage.getItem('checkout-payment-selected-method', {}),
        paymentDetails: appSessionStorage.getItem('checkout-payment-details', {}),
        shippingOptions: appSessionStorage.getItem('checkout-shipping-options', {}),
    };
};

const getters = {
    couponCode: (state) => state.couponCode,
    couponStatus: (state) => state.couponStatus,
    couponMessage: (state) => state.couponMessage,
    couponRequestIsLoading: (state) => state.couponRequestIsLoading,
    paymentMethods: (state) => state.paymentMethods,
    paymentMethodConfig: (state) => state.paymentMethodConfig,
    loadingPaymentData: (state) => state.loadingPaymentData,
    selectedPaymentMethod: (state) => state.selectedPaymentMethod,
    paymentDetails: (state) => state.paymentDetails,
    shippingOptions: (state) => state.shippingOptions,
    loadingPlaceOrder: (state) => state.loadingPlaceOrder,
    checkoutErrorMessage: (state) => state.checkoutErrorMessage,
};

const mutations = {
    SET_COUPON_CODE(state, couponCode) {
        state.couponCode = couponCode;
        appSessionStorage.setItem('checkout-payment-coupon-code', couponCode);
    },
    SET_COUPON_STATUS(state, couponStatus) {
        state.couponStatus = couponStatus;
    },
    SET_PAYMENT_OPTIONS_BY_CODE(state, config) {
        const paymentMethodCode = config.paymentMethodCode;
        if (paymentMethodCode.includes('creditcard') && config.paymentOptions.cards) {
            state.paymentMethodOptions[paymentMethodCode] = config.paymentOptions.cards;
            return
        }
        if (paymentMethodCode.includes('ideal') && config.paymentOptions.banks) {
            state.paymentMethodOptions[paymentMethodCode] = config.paymentOptions.banks;
            return
        }
        // Advanced data not needed right now. If needed, return config.paymentOptions.
        state.paymentMethodOptions[paymentMethodCode] = []
    },
    SET_COUPON_MESSAGE(state, couponMessage) {
        state.couponMessage = couponMessage;
    },
    SET_COUPON_REQUEST_IS_LOADING(state, couponRequestIsLoading) {
        state.couponRequestIsLoading = couponRequestIsLoading;
    },
    SET_PAYMENT_METHODS(state, paymentMethods) {
        state.paymentMethods = paymentMethods;
    },
    SET_PAYMENT_METHOD_CONFIG(state, paymentMethodConfig) {
        state.paymentMethodConfig = paymentMethodConfig;
    },
    SET_LOADING_PAYMENT_DATA(state, loadingPaymentData) {
        state.loadingPaymentData = loadingPaymentData;
    },
    SET_SELECTED_PAYMENT_METHOD(state, selectedPaymentMethod) {
        state.selectedPaymentMethod = selectedPaymentMethod;
    },
    CLEAR_PAYMENT_DETAILS(state) {
        state.paymentDetails = {};
        appSessionStorage.removeItem('checkout-payment-details');
    },
    ADD_PAYMENT_DETAILS(state, paymentDetail) {
        state.paymentDetails = {...state.paymentDetails, ...paymentDetail};
        appSessionStorage.setItem('checkout-payment-details', state.paymentDetails);
    },
    REMOVE_PAYMENT_DETAILS(state, paymentDetail) {
        if (state.paymentDetails.hasOwnProperty(paymentDetail)) {
            delete state.paymentDetails[paymentDetail];
        }
        appSessionStorage.setItem('checkout-payment-details', state.paymentDetails);
    },

    CLEAR_SHIPPING_OPTIONS(state) {
        state.shippingOptions = {};
        appSessionStorage.removeItem('checkout-shipping-options');
    },
    ADD_SHIPPING_OPTIONS(state, shippingOption) {
        state.shippingOptions = {...state.shippingOptions, ...shippingOption};
        appSessionStorage.setItem('checkout-shipping-options', state.shippingOptions);
    },
    REMOVE_SHIPPING_OPTIONS(state, shippingOption) {
        if (state.shippingOptions.hasOwnProperty(shippingOption)) {
            delete state[shippingOption];
        }
        appSessionStorage.setItem('checkout-shipping-options', state.shippingOptions);
    },
    SET_LOADING_ORDER(state, payload) {
        state.loadingPlaceOrder = payload;
    },
    SET_CHECKOUT_ERROR_MESSAGE(state, payload) {
        state.checkoutErrorMessage = payload;
    }
};

const actions = {
    async setSelectedPaymentMethod({commit, dispatch}, paymentMethod): Promise<void> {
        commit('SET_SELECTED_PAYMENT_METHOD', paymentMethod);
        appSessionStorage.setItem('checkout-payment-selected-method', paymentMethod);
    },
    async setPaymentMethod({state, rootState, rootGetters, dispatch, commit}, paymentMethod) {
        commit('CheckoutTotals/SET_TOTALS_AVAILABLE', false, {root: true});
        let additionalData = {};

        if (paymentMethod.code === 'buckaroo_magento2_payperemail') {
            additionalData = {
                'customer_email': window.Config['selected_customer']['email'] || rootGetters['CheckoutGlobal/customerEmail'],
                'customer_billingFirstName': rootGetters['CheckoutAddress/billingAddress'].firstname,
                'customer_billingLastName': rootGetters['CheckoutAddress/billingAddress'].lastname,
                'customer_gender': 1
            };

            commit('ADD_PAYMENT_DETAILS', additionalData);
        }

        const [_billingAddress, _shippingAddress] = rootGetters['CheckoutAddress/getAddressData'];

        try {
            await this.$solarClient.post(`/api/checkout/set-payment-information`, {
                email: rootGetters['CheckoutGlobal/customerEmail'],
                paymentMethod: {
                    method: paymentMethod['code'],
                    additional_data: additionalData,
                },
            });

            // Collect totals.
            await dispatch('CheckoutTotals/fetchCartTotals', null, {root: true});
        } catch (err) {
            //
        }
    },
    async collectPaymentMethods({rootGetters, commit}): Promise<void> {
        try {
            commit('SET_LOADING_PAYMENT_DATA', true);

            const {method_code, carrier_code} = rootGetters['CheckoutShipping/shippingMethod'];
            const [_billingAddress, _shippingAddress] = rootGetters['CheckoutAddress/getAddressData'];
            const _extensionAttributes = rootGetters['CheckoutGlobal/getExtensionAttributes'];

            const {data} = await this.$solarClient.post('/api/checkout/payment/collectPaymentMethods', {
                addressInformation: {
                    shippingAddress: _shippingAddress,
                    shippingMethodCode: method_code,
                    shippingCarrierCode: carrier_code,
                    extensionAttributes: _extensionAttributes,
                },
            });

            commit('SET_PAYMENT_METHODS', data['payment_methods']);
            commit('SET_PAYMENT_METHOD_CONFIG', data['payment_config'] || {});
            if (data['payment_methods'].length > 0) {
                commit('SET_SELECTED_PAYMENT_METHOD', data['payment_methods'][0]);
            }
            commit('CheckoutTotals/SET_CART_TOTALS', data['totals'], {root: true});
            commit('CheckoutTotals/SET_CART_ITEMS', data['totals']['items'], {
                root: true,
            });
        } catch (err) {
            //
        } finally {
            commit('SET_LOADING_PAYMENT_DATA', false);
        }
    },
    async placeOrder({rootGetters, getters, dispatch}): Promise<AxiosResponse> {
      const url: string = getters.selectedPaymentMethod['code'].includes('buckaroo') ? `${Environment().get('solar_origin_url')}/api/buckaroo-payment-information` : `${Environment().get('solar_origin_url')}/api/checkout/payment/placeOrder`;
      const [_billingAddress] = rootGetters['CheckoutAddress/getAddressData'];
      try {
        const response = await this.$mageClient.post(url, {
          cartId: rootGetters['CheckoutGlobal/getQuoteIdentifier'],
          email: rootGetters['CheckoutGlobal/customerEmail'],
          shippingOptions: getters.shippingOptions,
          paymentMethod: {
            method: getters.selectedPaymentMethod['code'],
            additional_data: getters.paymentDetails
          },
        });

        return JSON.parse(response.data);
      } catch (e) {
        const { message } = await e.response.data;
        console.log(message);
        window.scrollTo(0, 0);
      }
    },
    placeOrderAction: async function ({dispatch, commit}): Promise<void> {
        try {
            commit('SET_LOADING_ORDER', true);

            const data = await dispatch('placeOrder');

            if (data.hasOwnProperty('RequiredAction')) {
                window.location.href = data['RequiredAction']['RedirectURL'];
                return;
            }

            window.location.replace('/checkout/onepage/success');
        } catch (err) {
            try {
                const response = JSON.parse(err.request.response);
                commit('SET_CHECKOUT_ERROR_MESSAGE', response['exception']['message'] || 'Unable to place order, please try again later.');
                window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
            } catch (e) {
                commit('SET_CHECKOUT_ERROR_MESSAGE', 'Unable to place order, please try again later.');
                window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
            } finally {
                commit('SET_LOADING_ORDER', false);
            }
        }
    },
    async getCouponCode({commit}): Promise<boolean> {
        try {
          const { data } = await this.$mageClient.get(`${Environment().get('solar_origin_url')}/api/checkout/payment/coupons`);

            if (data.length) {
                commit('SET`_COUPON_CODE', data);
                commit('SET_COUPON_STATUS', true);
                return true;
            }

            return false;
        } catch (e) {
            return false;
        }
    },
    async applyCouponCode({commit}, couponCode): Promise<void> {
        commit('SET_COUPON_STATUS', false);
        commit('SET_COUPON_REQUEST_IS_LOADING', true);
        try {
          const { data } = await this.$mageClient.put(`${Environment().get('solar_origin_url')}/api/checkout/payment/coupons/${couponCode}`);

            if (data['couponStatus']) {
                commit('SET_COUPON_CODE', couponCode);
                commit('SET_COUPON_STATUS', true);
            }

            commit('CheckoutTotals/SET_CART_TOTALS', data['totals'], {root: true});
            commit('CheckoutTotals/SET_CART_ITEMS', data['totals']['items'], {
                root: true,
            });
        } catch ({response}) {
            commit('SET_COUPON_MESSAGE', response.data.message);
            commit('SET_COUPON_STATUS', false);
        } finally {
            commit('SET_COUPON_REQUEST_IS_LOADING', false);
        }
    },
    async removeCouponCode({commit}): Promise<void> {
        commit('SET_COUPON_REQUEST_IS_LOADING', true);
        try {
          const { data } = await this.$mageClient.delete(`${Environment().get('solar_origin_url')}/api/checkout/payment/coupons`);
            commit('SET_COUPON_CODE', '');
            commit('SET_COUPON_STATUS', null);

            commit('CheckoutTotals/SET_CART_TOTALS', data['totals'], {root: true});
            commit('CheckoutTotals/SET_CART_ITEMS', data['totals']['items'], {
                root: true,
            });
        } catch (e) {
            commit('SET_COUPON_MESSAGE', 'The coupon code could not be cancelled, please try again later.');
        } finally {
            commit('SET_COUPON_REQUEST_IS_LOADING', false);
        }
    },
    async getPaymentMethodOptions({commit}, {paymentMethodCode, paymentProvider}): Promise<any> {
        if (state.paymentMethodConfig['payment']) {
            const config: object = state.paymentMethodConfig['payment'][paymentProvider];
            if ( ! config) {
                return {};
            }

            if (paymentMethodCode in config) {
                commit('SET_PAYMENT_OPTIONS_BY_CODE', {
                    'paymentOptions': config[paymentMethodCode],
                    'paymentMethodCode': paymentMethodCode
                });
            }

            return state.paymentMethodOptions[paymentMethodCode];
        }
    }
};

const state = initialState();

const CheckoutPayment = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

export default CheckoutPayment;
